<template>
  <layout-default-dynamic>
    <b-container>
      <router-view />
    </b-container>
  </layout-default-dynamic>
</template>

<script>
import LayoutDefaultDynamic from '../layouts/LayoutDefaultDynamic'
import { BContainer } from 'bootstrap-vue'
import '../plugins/moment'

export default {
  name: 'blog',
  components: {
    LayoutDefaultDynamic,
    'b-container': BContainer,
  },
  metaInfo() {
    return {
      title: `zijschittert - blog`,
      meta: [
        {
          name: 'description',
          content:
            'Coaching voor vrouwen vanuit bijbels perspectief. - Bible-based coaching for women.',
        },
      ],
    }
  },
}
</script>
