import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import i18n from './i18n'
import EventBus from '../event-bus'
import 'moment/locale/nl'

Vue.use(VueMoment, {
  moment,
})

moment.tz.setDefault('Etc/GMT')
moment.locale(i18n.locale)

EventBus.$on('localeChange', (locale) => {
  moment.locale(locale)
})
